.tabulator .tabulator-header .tabulator-col {
    background-color: lightgray;
    color: black;
    font-weight: 700;
    font-size: medium;
}

.tabulator {
    border: 1;
}

.tabulator-row .tabulator-cell {
    padding: 4px 4px 4px 0px!important;
    font-size: 15px;
    vertical-align:unset;
}

/*.tabulator-row{
    height: 30px!important;
}*/


.tabulator-row.tabulator-row-even {
background-color: white;
}

.tabulator .tabulator-header .tabulator-col:first-child {
    text-align: center;
}

.tabulator-print-header, tabulator-print-footer{
    text-align:center;
    font-size: 1.875rem; /* 30px */
    line-height: 2.25rem; /* 36px */
    font-weight: 600;
    color: black;
}
.mypanel > div.p-dialog-content {
    /*background-color: transparent!important;*/
    box-shadow: none;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:85vw;
    max-width: 300px;
    margin: auto;
    padding: 15px!important;
    border-radius: 15px!important;
    overflow: hiddenv;
    /*flex-grow: 0.5!important;*/
}

-webkit-scrollbar:horizontal {
    height: 11px;
}

.p-slider .p-slider-range,span.p-slider-handle.p-slider-handle-active:hover {
    background-color: #1976D2;
}

.p-dropdown:not(.p-disabled):hover {
    border-color: unset;
}

.p-dropdown:not(.p-disabled).p-focus {
    border-color: unset;
}

.p-focus {
    border-color: unset;
}

.p-button.p-button-danger{
color: #ffffff;
background: #ef4444;
border: 1px solid #ef4444;
}

.p-button{
    color: #ffffff;
    background: #6366f1;;
    border: 1px solid #6366f1;;
    }

.p-dialog .p-dialog-footer button {
    margin: 0 0.5rem 0 0;
    padding: 12px 20px;
    width: auto;
}

.p-checkbox .p-checkbox-box {
    border: 2px solid #d1d5db;
    background: #ffffff;
    width: 22px;
    height: 22px;
    color: #4b5563;
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight {
    border-color: #1d4ed8;
    background: #1d4ed8;
    color: #ffffff;
}

.p-progress-spinner-circle {
    stroke: rgb(69,10,10);
}

@keyframes p-progress-spinner-color {
    100%, 0% {
        stroke: #d62d20;
    }
    40% {
        stroke: #d62d20;
    }
    66% {
        stroke: #d62d20;
    }
    80%, 90% {
        stroke: #d62d20;
    }
}

.ant-select-dropdown {
    z-index: 25000;
}

.\[appearance\:none\] {
    -webkit-appearance: none!important;
    appearance: none!important;
  }
