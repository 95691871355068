.formatterCell{
    white-space: pre-wrap;
    width: 100%;
    height: 100%;
}

.tabulator-cell:has(div > div.red-bg) {
    background-color: indianred;
}

.tabulator-headers{
    height:20px !important;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content {
    padding: 0px 0px 0px 0px !important;
}

.p-toast {
    width: 85vw;
    max-width: 25rem;
}

div.tabulator-row:has(div):has(div):has(div.manualPriority){
    color: red;
    font-weight: 600;
}

div.tabulator-row:has(div):has(div):has(div.duplicate){
    color: blue;
    font-weight: 600;
}

.tabulator-row .tabulator-cell.tabulator-frozen.tabulator-frozen-left {
    border-right: 2px solid #dee2e6;
    padding-right:8px ;
}

